<template>
    <!-- ========== HEADER ========== -->
<header class="flex flex-wrap sm:justify-start sm:flex-col z-50 w-full bg-white border-b border-gray-200 text-sm pb-2 sm:pb-0 ">
  <!-- Topbar -->
  <div class="max-w-[85rem] mx-auto w-full px-4 sm:px-6 lg:px-8 invisible md:visible">
    <div class="flex row items-center justify-between gap-x-5 w-full py-2 sm:pt-2 sm:pb-0">
      <div class="w-screen flex row justify-between items-center">
        <div class="space-x-4 text-xs ms-4 flex row justify-center items-center mt-3">
          <div class="flex row justify-center items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="red" data-slot="icon" class="w-4  mr-2">
              <path stroke-linecap="round" stroke-linejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
            </svg>
            <a class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800" href="#">Cocody Angré, Rue des Bars, Abidjan</a>
          </div>
          <div class="flex row justify-center items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="red" data-slot="icon" class="w-4  mr-2">
             <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
            </svg>
            <a class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800" href="#">info@canadaininter.com</a>
          </div>   
        </div>

      </div>

      <div class="w-screen flex items-center justify-end gap-x-5 py-2 sm:pt-2 sm:pb-0">

        <a class="w-10 h-10 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-red hover:bg-red/10 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-1 focus:ring-gray-600" href="https://web.facebook.com/people/Canada-in-officiel/100092445507620/?mibextid=ZbWKwL">
          <svg class="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" viewBox="0 0 16 16">
            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
          </svg>
        </a>
        <a class="w-10 h-10 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-red hover:bg-red/10 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-1 focus:ring-gray-600" href="https://www.instagram.com/canadainofficiel/?igsh=OGQ5ZDc2ODk2ZA%3D%3D" target="_blank">
        <img src="@/assets/images/instagram.png" alt="instagram" width="16" class="flex-shrink-0">
         
        </a>
       


      

<!-- Language Dropdown -->
       <div class="locale-changer">
        <select v-model="$i18n.locale" class="py-2 px-3 inline-flex items-center gap-x-2 text-sm rounded-lg border border-red-200 bg-red text-white-800 shadow-sm hover:bg-red-50 disabled:opacity-50 disabled:pointer-events-none ">
          <option v-for="locale in $i18n.availableLocales" :key=" `locale-${locale}`" :value="locale" class="flex-shrink-0 w-4 h-4 text-gray-600">
            {{ locale }}
          </option>
        </select>
       </div>
<!-- End Language Dropdown -->


        <a class="inline-flex justify-center items-center gap-2 font-medium text-slate-600 hover:text-slate-500 text-sm" href="#">Forum</a>
        <a class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-red-600 text-red-600 hover:border-red-500 hover:text-red-500 disabled:opacity-50 disabled:pointer-events-none dark:border-red-500 dark:text-red-500 dark:hover:text-red-400 dark:hover:border-red-400 " href="https://app.casecloud.ca/auth/exists" target="_blank">{{ $t('portail_btn') }}</a>
      </div>
    </div>
  </div>
  <!-- End Topbar -->

  <nav class="relative max-w-[85rem] w-full mx-auto px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8" aria-label="Global">
    <div class="flex items-center justify-between">
      <img src="@/assets/images/logo.png" class="flex-none text-xl font-semibold mb-2" href="#" aria-label="Brand" width="70px" height="auto"/>
      <div class="sm:hidden">
        <button type="button" class="hs-collapse-toggle w-9 h-9 flex justify-center items-center text-sm font-semibold rounded-lg border border-gray-200 text-black hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none " data-hs-collapse="#navbar-collapse-with-animation" aria-controls="navbar-collapse-with-animation" aria-label="Toggle navigation">
          <svg class="hs-collapse-open:hidden flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="3" x2="21" y1="6" y2="6"/><line x1="3" x2="21" y1="12" y2="12"/><line x1="3" x2="21" y1="18" y2="18"/></svg>
          <svg class="hs-collapse-open:block hidden flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
        </button>
      </div>
    </div>
    <div id="navbar-collapse-with-animation" class="hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow sm:block">
      <div class="flex text-sm flex-col gap-y-4 gap-x-0 mt-5 sm:flex-row sm:items-center sm:justify-end sm:gap-y-0 sm:gap-x-7 sm:mt-0 sm:ps-7">
        <router-link :to="{'path': 'immigration'}" :class="{ 'active-link': $route.name === 'immigration' }" replace >
           <a class="font-medium sm:py-6" href="#" aria-current="page">{{ $t('Accueil') }}</a>
        </router-link>
        <div class="hs-dropdown [--strategy:static] sm:[--strategy:fixed] [--adaptive:none] sm:[--trigger:hover] sm:py-4 bg-white">
          <button type="button" class="flex items-center w-full text-gray-800 hover:text-gray-500 font-medium">
            {{ $t('Qui nous sommes') }} 
            <svg class="flex-shrink-0 ms-2 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m6 9 6 6 6-6"/></svg>
          </button>

          <div class="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] sm:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 sm:w-48 hidden z-10 bg-white sm:shadow-md rounded-lg p-2 before:absolute top-full sm:border before:-top-5 before:start-0 before:w-full before:h-5">
            <a class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500" href="#">
              {{ $t('Nos missions') }}  
            </a>
           
            <a class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500" href="#">
              {{ $t('Nos Valeurs') }}   
            </a>
          </div>
        </div>





        <div class="hs-dropdown [--strategy:static] sm:[--strategy:fixed] [--adaptive:none] sm:[--trigger:hover] sm:py-4 bg-white">
          <button type="button" class="flex items-center w-full text-gray-800 hover:text-gray-500 font-medium">
            {{ $t('Services') }} 
            <svg class="flex-shrink-0 ms-2 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m6 9 6 6 6-6"/></svg>
          </button>

          <div class="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] sm:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 sm:w-48 hidden z-10 bg-white sm:shadow-md rounded-lg p-2 before:absolute top-full sm:border before:-top-5 before:start-0 before:w-full before:h-5">
            <a class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500" href="#">
              {{ $t('Visa temporaire') }}  
            </a>
           
            <a class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 " href="#">
              {{ $t('Visa permanent') }}  
            </a>

            <a class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 " href="#">
              {{ $t('Test de français') }} 
            </a>
          </div>
        </div>
        <router-link :to="{'path': 'agence-voyage'}" replace :class="{ 'active-link': $route.name === 'agence-voyage' }" >
          <a class="font-medium hover:text-gray-500 sm:py-6" href="#">{{ $t('Agence de voyage') }} </a>
        </router-link>
        
        <a class="font-medium text-gray-800 hover:text-gray-500 sm:py-6" href="#"> {{ $t('Media') }}</a>
        <a class="font-medium text-gray-800 hover:text-gray-500 sm:py-6" href="#">{{ $t('Contact') }}</a>



        
      </div>
    </div>
  </nav>
</header>
<!-- ========== END HEADER ========== -->
</template>

<script>
export default {
  name: 'HeaderView',
  
}


</script>

<style>

.active-link {
  background-color: red;
  font-weight: bold;
  color: white; 
  padding: 5px 5px;

  border-radius: 8px;
}
</style>