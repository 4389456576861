<template>     
 <div class="h-screen w-screen">
  <div class="object-cover bg-red-600  bg-[url('https://www.aldjalia.com/images/image%206.jpg')] bg-no-repeat bg-center bg-cover  flex items-center h-full py-16">
    <main class="w-full max-w-md mx-auto p-6 ">
        
      <div class="mt-7 bg-white border border-gray-200 rounded-xl shadow-sm ">
        <div class="p-4 sm:p-7">
          <div class="text-center flex flex-col items-center justify-center ">
            <img src="@/assets/images/logo.png" class=" w-16 right-2/3" w/>
            <h1 class="block text-2xl font-bold text-gray-800">Canada <span class="text-red-600">in</span></h1>
            <div class="text-center mt-4 flex flex-row">
            <p class="text-sm text-gray-600 mr-2">
              Nous sommes 
            </p>
            <VueWriter class="text-red-600 decoration-2 hover:underline text-sm " :array="arr"/>
          </div>
          </div>

          <div class="mt-5">
            <!-- Form -->
            <form>
              <div class="grid gap-y-4">
                <a class="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-600 text-white hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none" href="https://canadaininter.com/immigration">Canada in Immigration</a>
                <a class="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-gray-600 text-white hover:bg-gray-700 disabled:opacity-50 disabled:pointer-events-none" href="https://canadaininter.com/agence-voyage">Canada in Voyage</a>
              </div>
            </form>
            <!-- End Form -->
          </div>
        </div>
      </div>
    </main>
  </div>
</div>
</template>

<script>

import VueWriter from 'vue-writer';


  export default {
    name: "PortailView",
    components:{
        VueWriter
    },
    data() {
      return {
         arr: ["des specialistes de immigration.", " une agence de voyage."] 
        };
    },
  }
</script>