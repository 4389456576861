<template>
   <HeaderView/>

 <!-- Hero -->
<div class="relative overflow-hidden mb-12">
  <div class="relative w-screen mx-auto">
      <div class="w-full object-cover h-96 sm:h-[480px] bg-[url('https://static.liligo.com/img/cms/fallback-gallery/flight/search-form-background/ultrawide/320/image@7.0.webp')] bg-no-repeat bg-center bg-cover rounded-xl"></div>
      <div class="absolute inset-0 bg-black opacity-50"></div>

      <div class="absolute inset-0 w-full h-full">
        <div class="flex flex-col justify-center items-center w-full h-full">
              <!-- Title -->
          <div class="max-w-3xl text-center mx-auto">
              <VueWriter class="block font-medium text-red-800 text-4xl sm:text-5xl md:text-6xl lg:text-7xl"   :array="arr"/>
          </div>

          <div class="max-w-3xl text-center mx-auto">
            <h1 class="block font-medium text-gray-200 text-4xl sm:text-5xl md:text-6xl lg:text-7xl">
              {{ $t('planification_desc_slide') }}
              </h1>
          </div>
          <!-- End Title -->

          <div class="text-left my-8">
            <button type="button" class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-600 text-white hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none" data-hs-overlay="#hs-notifications">
              {{ $t('reserve_billet_btn') }}
            </button>
          </div>
      

            <!-- Scroll down arrow -->
        <div class="mt-16">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="w-12 h-12 transform rotate-45 animate-bounce">
            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3" />
          </svg>
        </div>
        <!-- End Scroll down arrow -->
        </div>
      </div>

    </div>
</div>
<!-- End Hero --><!-- Hero -->
<div class="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8  mt-5">
  <!-- Grid -->
  <div class="grid md:grid-cols-2 gap-4 md:gap-8 xl:gap-20 md:items-center">


    <div class="relative ms-4">
      <img class="w-full rounded-md" src="@/assets/images/about-left.png" alt="Image Description">
    </div>
    <!-- End Col -->

    <div>
      <h1 class="block text-3xl font-bold text-gray-800 sm:text-4xl lg:text-6xl lg:leading-tight ">{{ $t('offre_voyage_title') }} <span class="text-red-600">Canada in</span></h1>
      <p class="mt-3 text-lg text-gray-800 ">Canada in {{ $t('offre_voyage_desc') }} </p>
      <div class="text-left my-5">
        <button type="button" class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-600 text-white hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none" data-hs-overlay="#hs-notifications">
          {{ $t('reserve_billet_btn') }}
        </button>
      </div>
      

        <!-- Icon Blocks -->
        <div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
          <div class="grid sm:grid-cols-2 lg:grid-cols-3 items-center gap-12">
            <!-- Icon Block -->
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="black" class="w-9 h-9">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
              </svg>

              <div class="bg-gradient-to-r from-gray-200 via-gray-50 to-white/0 h-0.5 mt-6">
                <div class="bg-gray-400 w-9 h-0.5"></div>
              </div>
              <div class="mt-5">
                <h3 class="text-lg font-semibold text-gray-800 ">{{ $t('offre_seg_1_title') }}</h3>
                <p class="mt-1 text-gray-600">{{ $t('offre_seg_1_desc') }}</p>
              </div>
            </div>
            <!-- End Icon Block -->

            <!-- Icon Block -->
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="black" class="w-9 h-9">
                <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 4.5 15 15m0 0V8.25m0 11.25H8.25" />
              </svg>


              <div class="bg-gradient-to-r from-gray-200 via-gray-50 to-white/0 h-0.5 mt-6">
                <div class="bg-gray-400 w-9 h-0.5"></div>
              </div>
              <div class="mt-5">
                <h3 class="text-lg font-semibold text-gray-800 ">{{ $t('offre_seg_2_title') }}</h3>
                <p class="mt-1 text-gray-600">{{ $t('offre_seg_2_desc') }}</p>
              </div>
            </div>
            <!-- End Icon Block -->

            <!-- Icon Block -->
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="black" class="w-9 h-9">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z" />
              </svg>


              <div class="bg-gradient-to-r from-gray-200 via-gray-50 to-white/0 h-0.5 mt-6">
                <div class="bg-gray-400 w-9 h-0.5"></div>
              </div>
              <div class="mt-5">
                <h3 class="text-lg font-semibold text-gray-800 ">{{ $t('offre_seg_3_title') }}</h3>
                <p class="mt-1 text-gray-600">{{ $t('offre_seg_3_desc') }} </p>
              </div>
            </div>
            <!-- End Icon Block -->

          </div>
        </div>
        <!-- End Icon Blocks -->

    </div>
    <!-- End Col -->
    

  </div>
  <!-- End Grid -->
</div>
<!-- End Hero -->


<!-- Card Blog -->
<div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto mt-12">

  <h1 class="block text-3xl font-bold text-gray-800 sm:text-4xl lg:text-6xl lg:leading-tight "> <span class="text-red-600">{{ $t('voyage_service_title') }}</span></h1>

  <div class=" h-0.5 flex flex-row  mt-3 mb-12">
        <div class="bg-red-600 w-32 h-0.5"></div>
  </div>

  <!-- Grid -->
  <div class="grid lg:grid-cols-2 lg:gap-y-16 gap-10">
    <!-- Card -->
    <a class="group rounded-xl overflow-hidden dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="#">
      <div class="sm:flex">
        <div class="flex-shrink-0 relative rounded-xl overflow-hidden w-full sm:w-56 h-44">
          <img class="group-hover:scale-105 transition-transform duration-500 ease-in-out w-full h-full absolute top-0 start-0 object-cover rounded-xl" src="https://orniposition.com/wp-content/uploads/2021/06/14430-1552044335-shutterstock-515243494.jpg" alt="Image Description">
        </div>

        <div class="grow mt-4 sm:mt-0 sm:ms-6 px-4 sm:px-0">
          <h3 class="text-xl font-semibold text-gray-800 group-hover:text-gray-600 ">
            {{ $t('voyage_service_sect1_title') }}
            
          </h3>
          <p class="mt-3 text-gray-600 ">
            {{ $t('voyage_service_sect1_desc') }}
          </p>
          <p class="mt-4 inline-flex items-center gap-x-1 text-gray-200 decoration-2 hover:underline font-medium">
            {{ $t('btn_savoir_plus') }}
            <svg class="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6"/></svg>
          </p>
        </div>
      </div>
    </a>
    <!-- End Card -->

    <!-- Card -->
    <a class="group rounded-xl overflow-hidden " href="#">
      <div class="sm:flex">
        <div class="flex-shrink-0 relative rounded-xl overflow-hidden w-full sm:w-56 h-44">
          <img class="group-hover:scale-105 transition-transform duration-500 ease-in-out w-full h-full absolute top-0 start-0 object-cover rounded-xl" src="https://www.algerie-eco.com/wp-content/uploads/2016/03/le-meridien-oran.jpg" alt="Image Description">
        </div>

        <div class="grow mt-4 sm:mt-0 sm:ms-6 px-4 sm:px-0">
          <h3 class="text-xl font-semibold text-gray-800 group-hover:text-gray-600 ">
            {{ $t('voyage_service_sect2_title') }}
          </h3>
          <p class="mt-3 text-gray-600 ">
            {{ $t('voyage_service_sect2_desc') }}
            </p>
            <p class="mt-4 inline-flex items-center gap-x-1 text-gray-200 decoration-2 hover:underline font-medium">
              {{ $t('btn_savoir_plus') }}
            <svg class="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6"/></svg>
          </p>
        </div>
      </div>
    </a>
    <!-- End Card -->

    <!-- Card -->
    <a class="group rounded-xl overflow-hidden " href="#">
      <div class="sm:flex">
        <div class="flex-shrink-0 relative rounded-xl overflow-hidden w-full sm:w-56 h-44">
          <img class="group-hover:scale-105 transition-transform duration-500 ease-in-out w-full h-full absolute top-0 start-0 object-cover rounded-xl" src="https://discover-ivorycoast.com/wp-content/uploads/2019/06/sand-937387_1280-1.jpg" alt="Image Description">
        </div>

        <div class="grow mt-4 sm:mt-0 sm:ms-6 px-4 sm:px-0">
          <h3 class="text-xl font-semibold text-gray-800 group-hover:text-gray-600">
            {{ $t('voyage_service_sect3_title') }}
          </h3>
          <p class="mt-3 text-gray-600">
            {{ $t('voyage_service_sect3_desc') }}          
            </p>
          <p class="mt-4 inline-flex items-center gap-x-1 text-gray-200 decoration-2 hover:underline font-medium">
            {{ $t('btn_savoir_plus') }}
            <svg class="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6"/></svg>
          </p>
        </div>
      </div>
    </a>
    <!-- End Card -->

    <!-- Card -->
    <a class="group rounded-xl overflow-hidden dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="#">
      <div class="sm:flex">
        <div class="flex-shrink-0 relative rounded-xl overflow-hidden w-full sm:w-56 h-44">
          <img class="group-hover:scale-105 transition-transform duration-500 ease-in-out w-full h-full absolute top-0 start-0 object-cover rounded-xl" src="https://www.destinationlemonde.com/wp-content/uploads/2020/09/parc-location-auto.jpg" alt="Image Description">
        </div>

        <div class="grow mt-4 sm:mt-0 sm:ms-6 px-4 sm:px-0">
          <h3 class="text-xl font-semibold text-gray-800 group-hover:text-gray-600 ">
            {{ $t('voyage_service_sect4_title') }}
          </h3>
          <p class="mt-3 text-gray-600 dark:text-gray-400">
            {{ $t('voyage_service_sect3_desc') }}           
            </p>
          <p class="mt-4 inline-flex items-center gap-x-1 text-gray-200 decoration-2 hover:underline font-medium">
            {{ $t('btn_savoir_plus') }}
            <svg class="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6"/></svg>
          </p>
        </div>
      </div>
    </a>
    <!-- End Card -->
  </div>
  <!-- End Grid -->
</div>
<!-- End Card Blog -->

<!-- Card Blog -->
<div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
  
  <h1 class="block text-3xl font-bold text-gray-800 sm:text-4xl lg:text-6xl lg:leading-tight "> <span class="text-gray-900">{{ $t('voyage_blog_title') }}</span></h1>

  <div class=" h-0.5 flex flex-row  mt-3 mb-12">
        <div class="bg-red-600 w-32 h-0.5"></div>
  </div>

  <!-- Grid -->
  <div class="grid sm:grid-cols-2 gap-6">
    <!-- Card -->
    <a class="group rounded-xl overflow-hidden " href="#">
      <div class="relative pt-[50%] sm:pt-[70%] rounded-xl overflow-hidden">
        <img class="w-full h-full absolute top-0 start-0 object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out rounded-xl" src="https://static.liligo.com/img/cms/img/magazine-voyage/wp-content/uploads/sites/42/2021/12/lago-di-braies-dolomites-istock-header-1160x772.webp" alt="Image Description">
        <span class="absolute top-0 end-0 rounded-se-xl rounded-es-xl text-xs font-medium bg-red-800 text-white py-1.5 px-3 ">
          Infos
        </span>
      </div>

      <div class="mt-7">
        <h3 class="text-xl font-semibold text-gray-800 group-hover:text-gray-600">
          {{ $t('voyage_blog_sect1_title') }}
        </h3>
        <p class="mt-3 text-gray-800 ">
          {{ $t('voyage_blog_sect1_desc') }}           
          </p>
        
      </div>
    </a>
    <!-- End Card -->

    <!-- Card -->
    <a class="group rounded-xl overflow-hidden " href="#">
      <div class="relative pt-[50%] sm:pt-[70%] rounded-xl overflow-hidden">
        <img class="w-full h-full absolute top-0 start-0 object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out rounded-xl" src="https://static.liligo.com/img/cms/img/magazine-voyage/wp-content/uploads/sites/42/2022/10/perito-moreno-glacier-argentine-1200x550-1-1160x772.webp" alt="Image Description">
       
      </div>

      <div class="mt-7">
        <h3 class="text-xl font-semibold text-gray-800 group-hover:text-gray-600">
          {{ $t('voyage_blog_sect2_title') }}
        </h3>
        <p class="mt-3 text-gray-800 ">
          {{ $t('voyage_blog_sect2_desc') }}  
           </p>
      </div>
    </a>
    <!-- End Card -->

   

  </div>
  <!-- End Grid -->
</div>
<!-- End Card Blog -->

<div class="bg-gray-200">
      <!-- Blog Article -->
<div class="max-w-[85rem] px-4 pt-6 lg:pt-10 pb-12 sm:px-6 lg:px-8 mx-auto">
  <div class="max-w-[85rem] bg-white mt-5 p-10">
    <!-- Content -->
    <div class="space-y-5 md:space-y-8">
      <div class="space-y-3">
        <h2 class="text-2xl font-bold md:text-3xl"> {{ $t('voyage_reserve_title') }}  </h2>
        <h2 class="text-xl font-bold">{{ $t('voyage_reserve_bloc_desc') }}</h2>
        <p class="text-lg text-gray-800">{{ $t('voyage_reserve_bloc_desc1') }}</p>
        <h2 class="text-xl font-bold">{{ $t('voyage_reserve_title2') }}</h2>
        <p class="text-lg text-gray-800">{{ $t('voyage_reserve_bloc_desc2') }}</p>
        <p class="text-lg text-gray-800">{{ $t('voyage_reserve_desc_step3') }}</p>
        <p class="text-lg text-gray-800"> * {{ $t('voyage_reserve_desc_step3_line1') }} <br/>
          * {{ $t('voyage_reserve_desc_step3_line2') }}<br/>
          * {{ $t('voyage_reserve_desc_step3_line3') }}<br/>
          * {{ $t('voyage_reserve_desc_step3_line4') }}<br/>
          * {{ $t('voyage_reserve_desc_step3_line5') }}<br/>
          * {{ $t('voyage_reserve_desc_step3_line6') }}<br/>
          * {{ $t('voyage_reserve_desc_step3_line7') }}<br/>
          * {{ $t('voyage_reserve_desc_step3_line8') }}<br/>
          * {{ $t('voyage_reserve_desc_step3_line9') }}<br/>
          * {{ $t('voyage_reserve_desc_step3_line10') }}<br/>
   </p>
   <h2 class="text-xl font-bold">{{ $t('voyage_reserve_title4') }}</h2>
   <p class="text-lg text-gray-800">{{ $t('voyage_reserve_desc4') }}</p>

      </div>
    </div>
    <!-- End Content -->
  </div>
</div>
<!-- End Blog Article -->
</div>


<div id="hs-notifications" class="hs-overlay hidden w-full h-full fixed top-0 start-0 z-[60] overflow-x-hidden overflow-y-auto">
  <div class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto">
    <div class="relative flex flex-col bg-white border shadow-sm rounded-xl overflow-hidden ">
      <div class="absolute top-2 end-2">
        <button type="button" class="flex justify-center items-center w-7 h-7 text-sm font-semibold rounded-lg border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none" data-hs-overlay="#hs-notifications">
          <span class="sr-only">Close</span>
          <svg class="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
        </button>
      </div>

      <div class="p-4 sm:p-10 overflow-y-auto">
        <div class="mb-6 text-center">
          <h3 class="mb-2 text-xl font-bold text-red-600">
            {{ $t('reserve_form_title') }}
          </h3>
          <p class="text-gray-500">
            {{ $t('reserve_form_desc') }}
          </p>
        </div>

        <div class="py-3 flex items-center text-xs text-gray-400 uppercase before:flex-[1_1_0%] before:border-t before:border-gray-200 before:me-6 after:flex-[1_1_0%] after:border-t after:border-gray-200 after:ms-6 dark:text-gray-500 dark:before:border-gray-600 dark:after:border-gray-600">Informations à fournis</div>

        <!-- Form -->
        <form @submit.prevent="submitForm">
          <div class="grid gap-y-4">
            <!-- Form Group -->
            <div>
              <label for="email" class="block text-sm mb-2">{{ $t('reserve_form_input_mail') }}</label>
              <div class="relative">
                <input type="email" id="email" name="email" v-model="formData.email" class="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6" required>
              </div>
            </div>
            <!-- End Form Group -->

            <!-- Form Group -->
            <div>
              <label for="email" class="block text-sm mb-2">{{ $t('reserve_form_input_name') }}</label>
              <div class="relative">
                <input type="text" id="name" name="name" v-model="formData.name" class="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6" required>
              </div>
            </div>
            <!-- End Form Group -->

            <!-- Form Group -->
            <div>
              <label for="email" class="block text-sm mb-2">{{ $t('reserve_form_input_phone') }}</label>
              <div class="relative">
                <input type="phone" id="phone" name="phone" v-model="formData.phone" class="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6" required>
              </div>
            </div>
            <!-- End Form Group -->

            <!-- Form Group -->
            <div>
              <label for="destination" class="block text-sm mb-2">{{ $t('reserve_form_input_destination') }}</label>
              <div class="relative">
                <input type="text" id="destination" name="destination" v-model="formData.destination" class="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6" required>
              </div>
            </div>
            <!-- End Form Group -->

            <div>
              <div class="relative">
                <div class="flex justify-between items-center">
                    <label for="hs-textarea-with-corner-hint" class="block text-sm font-medium mb-2 ">{{ $t('reserve_form_input_infoCompl') }}</label>
                    <span class="block text-sm text-gray-500 mb-2">100 characters</span>
                  </div>
                  <textarea id="hs-textarea-with-corner-hint" v-model="formData.infos" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-red-500 focus:ring-red-500 disabled:opacity-50 disabled:pointer-events-none shadow-sm ring-1 ring-inset ring-gray-300" rows="3" :placeholder="$t('reser_form_ido')"></textarea>
                </div>
            </div>
            <!-- End Form Group -->
            <button type="submit" class="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-600 text-white hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none  ">{{ $t('reserve_form_btn_now') }}</button>
          </div>
        </form>
        <!-- End Form -->

      </div>
    </div>
  </div>
</div>







<FooterViewVue/>
</template>

<script>
// @ is an alias to /src
import HeaderView from './templates/HeaderView.vue';
import VueWriter from 'vue-writer';
import FooterViewVue from './templates/FooterView.vue';


export default {
  name: 'AgenceVoyageView',
  components: {
    HeaderView,
    VueWriter,
    FooterViewVue
  },
  data() {
      return {
         arr: [ this.$t('confier_title')] ,
         formData: {
            name: '',
            email: '',
            phone: '',
            destination: '',
            infos: ''
            
          }
        };
  },
  methods: {
    async submitForm() {
      try {
        const response = await fetch('https://canadaininter.com/sendMail.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.formData)
        });

        const data = await response.json();
        if (data.success) {
          alert('Formulaire soumis avec succès !');
        } else {
          alert('Erreur lors de la soumission du formulaire.');
        }
      } catch (error) {
        console.error('Erreur:', error);
        alert('Une erreur s\'est produite.');
      }
    }
  }

}
</script>


<style>
/* Animation pour la flèche */
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
}

.animate-bounce {
    animation: bounce 1.5s infinite;
}
</style>