import { createI18n } from "vue-i18n"
import en from './locales/en.json'
import fr from './locales/fr.json'

function loadLocaleMessages(){
    const locales = [{ Français: fr }, { Anglais: en }]
    const messages = {}
    locales.forEach(lang => {
        const key = Object.keys(lang)
        messages[key] = lang[key]
    })
    return messages
}

export default createI18n({
    locale: 'Français',
    fallbackLocale: 'Français',
    messages: loadLocaleMessages()
})