<template>
  <HeaderView />

<!-- Hero -->
<div class="max-w-[85rem] mx-auto mt-5 px-4 sm:px-6 lg:px-8 overflow-hidden">
  <!-- Grid -->
  <div class="grid lg:grid-cols-7 lg:gap-x-8 xl:gap-x-12 lg:items-center">
    <div class="lg:col-span-3">
      
      <h1 class="block text-3xl font-bold text-gray-800 sm:text-4xl md:text-5xl lg:text-6xl">
        {{ $t('static_slide_title') }}
      </h1>
      <VueWriter class="block text-3xl font-bold text-red-600 sm:text-4xl md:text-5xl lg:text-6xl" :array="arr"/>
      <p class="mt-3 text-lg text-gray-800 ">{{ $t('desc_slide_general') }}</p>

      
     

      <!-- Brands -->
      <div class="mt-3">
        <span class="text-xs font-medium text-red-600 uppercase ">{{ $t('approuv_title') }}:</span>
        <div class="mt-4 flex row-auto items-center justify-center gap-x-4">
          <img src="@/assets/images/cicc.jpeg" class="w-52 lg:w-52 sm:w-1" href="#" aria-label="Brand"/>
          <img src="@/assets/images/cic.jpeg" class="w-52 lg:w-52 lg:h-16 sm:w-1 sm:h-1" href="#" aria-label="Brand" />
          <img src="@/assets/images/consultantCana.jpeg" class="w-20 lg:h-20 lg:w-20 sm:w-1 sm:h-1" href="#" aria-label="Brand"/>
        </div>
      </div>
      <!-- End Brands -->
    </div>
    <!-- End Col -->

    <div class="lg:col-span-4 mt-10 lg:mt-0">
      <img class="w-full rounded-xl" src="@/assets/images/slide.jpeg" alt="Image Description">
    </div>
    <!-- End Col -->
  </div>
  <!-- End Grid -->


<div class="px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mt-5 mx-auto">
  <div class="flex col justify-center items-center mb-10">
        <h2 class="text-3xl text-gray-800 font-bold lg:text-4xl">
          {{ $t('option_immigration_title') }} 
        </h2>
    </div>
</div>

<div class="grid sm:grid-cols-2 lg:grid-cols-3 items-center gap-12">
    <!-- Icon Block -->
    <div class="text-center">
      <div class="flex justify-center items-center w-16 h-16 bg-red-600 border border-gray-200 rounded-full mx-auto">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" data-slot="icon" class="w-9 h-9">
          <path fill-rule="evenodd" d="M7.502 6h7.128A3.375 3.375 0 0 1 18 9.375v9.375a3 3 0 0 0 3-3V6.108c0-1.505-1.125-2.811-2.664-2.94a48.972 48.972 0 0 0-.673-.05A3 3 0 0 0 15 1.5h-1.5a3 3 0 0 0-2.663 1.618c-.225.015-.45.032-.673.05C8.662 3.295 7.554 4.542 7.502 6ZM13.5 3A1.5 1.5 0 0 0 12 4.5h4.5A1.5 1.5 0 0 0 15 3h-1.5Z" clip-rule="evenodd" />
          <path fill-rule="evenodd" d="M3 9.375C3 8.339 3.84 7.5 4.875 7.5h9.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625V9.375ZM6 12a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H6.75a.75.75 0 0 1-.75-.75V12Zm2.25 0a.75.75 0 0 1 .75-.75h3.75a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75ZM6 15a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H6.75a.75.75 0 0 1-.75-.75V15Zm2.25 0a.75.75 0 0 1 .75-.75h3.75a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75ZM6 18a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H6.75a.75.75 0 0 1-.75-.75V18Zm2.25 0a.75.75 0 0 1 .75-.75h3.75a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75Z" clip-rule="evenodd" />
        </svg>
      </div>
      <div class="mt-3">
        <h3 class="text-lg font-semibold text-gray-800 ">{{ $t('sect_1') }}</h3>
        <p class="mt-1 text-gray-600 "> {{ $t('desc_sect_1') }}</p>
        <p class="mt-5 inline-flex items-center gap-x-1 text-black decoration-2 group-hover:underline font-medium bg-gray-200 p-2 rounded-md border-gray-400">
          {{ $t('sect_1') }}
          <svg class="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6"/></svg>
        </p>
      </div>
    </div>
    <!-- End Icon Block -->

      <!-- Icon Block -->
    <div class="text-center">
      <div class="flex justify-center items-center w-16 h-16 bg-red-600 border border-gray-200 rounded-full mx-auto">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="w-9 h-9">
         <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 21v-7.5a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349M3.75 21V9.349m0 0a3.001 3.001 0 0 0 3.75-.615A2.993 2.993 0 0 0 9.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 0 0 2.25 1.016c.896 0 1.7-.393 2.25-1.015a3.001 3.001 0 0 0 3.75.614m-16.5 0a3.004 3.004 0 0 1-.621-4.72l1.189-1.19A1.5 1.5 0 0 1 5.378 3h13.243a1.5 1.5 0 0 1 1.06.44l1.19 1.189a3 3 0 0 1-.621 4.72M6.75 18h3.75a.75.75 0 0 0 .75-.75V13.5a.75.75 0 0 0-.75-.75H6.75a.75.75 0 0 0-.75.75v3.75c0 .414.336.75.75.75Z" />
       </svg>


      </div>
      <div class="mt-3">
        <h3 class="text-lg font-semibold text-gray-800 ">{{ $t('sect_2') }}</h3>
        <p class="mt-1 text-gray-600 ">{{ $t('desc_sect_2') }}</p>
        <p class="mt-5 inline-flex items-center gap-x-1 text-black decoration-2 group-hover:underline font-medium bg-gray-200 p-2 rounded-md border-gray-400">
          {{ $t('btn_savoir_plus') }}
          <svg class="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6"/></svg>
        </p>
      </div>
    </div>
    <!-- End Icon Block -->

      <!-- Icon Block -->
    <div class="text-center">
      <div class="flex justify-center items-center w-16 h-16 bg-red-600 border border-gray-200 rounded-full mx-auto">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="w-9 h-9">
            <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z" />
       </svg>
      </div>
      <div class="mt-3">
        <h3 class="text-lg font-semibold text-gray-800 ">{{ $t('sect_3') }}</h3>
        <p class="mt-1 text-gray-600 ">{{ $t('desc_sect_3') }}</p>
        <p class="mt-5 inline-flex items-center gap-x-1 text-black decoration-2 group-hover:underline font-medium bg-gray-200 p-2 rounded-md border-gray-400">
          {{ $t('btn_savoir_plus') }}
          <svg class="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6"/></svg>
        </p>
      </div>
    </div>
    <!-- End Icon Block -->
     

  <!-- Icon Block -->
  <div class="text-center mt-4">
      <div class="flex justify-center items-center w-16 h-16 bg-red-600 border border-gray-200 rounded-full mx-auto">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="w-9 h-9">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 3v17.25m0 0c-1.472 0-2.882.265-4.185.75M12 20.25c1.472 0 2.882.265 4.185.75M18.75 4.97A48.416 48.416 0 0 0 12 4.5c-2.291 0-4.545.16-6.75.47m13.5 0c1.01.143 2.01.317 3 .52m-3-.52 2.62 10.726c.122.499-.106 1.028-.589 1.202a5.988 5.988 0 0 1-2.031.352 5.988 5.988 0 0 1-2.031-.352c-.483-.174-.711-.703-.59-1.202L18.75 4.971Zm-16.5.52c.99-.203 1.99-.377 3-.52m0 0 2.62 10.726c.122.499-.106 1.028-.589 1.202a5.989 5.989 0 0 1-2.031.352 5.989 5.989 0 0 1-2.031-.352c-.483-.174-.711-.703-.59-1.202L5.25 4.971Z" />
       </svg>

      </div>
      <div class="mt-3">
        <h3 class="text-lg font-semibold text-gray-800 ">{{ $t('sect_4') }}</h3>
        <p class="mt-1 text-gray-600 ">{{ $t('desc_sect_4') }}</p>
        <p class="mt-5 inline-flex items-center gap-x-1 text-black decoration-2 group-hover:underline font-medium bg-gray-200 p-2 rounded-md border-gray-400">
          {{ $t('btn_savoir_plus') }}
          <svg class="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6"/></svg>
        </p>
      </div>
    </div>
    <!-- End Icon Block -->

      <!-- Icon Block -->
      <div class="text-center">
      <div class="flex justify-center items-center w-16 h-16 bg-red-600 border border-gray-200 rounded-full mx-auto">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="w-9 h-9">
           <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z" />
        </svg>

      </div>
      <div class="mt-3">
        <h3 class="text-lg font-semibold text-gray-800 ">{{ $t('sect_5') }}</h3>
        <p class="mt-1 text-gray-600 ">{{ $t('desc_sect_5') }}</p>
        <p class="mt-5 inline-flex items-center gap-x-1 text-black decoration-2 group-hover:underline font-medium bg-gray-200 p-2 rounded-md border-gray-400">
          {{ $t('btn_savoir_plus') }}
          <svg class="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6"/></svg>
        </p>
      </div>
    </div>
    <!-- End Icon Block -->

      <!-- Icon Block -->
      <div class="text-center">
      <div class="flex justify-center items-center w-16 h-16 bg-red-600 border border-gray-200 rounded-full mx-auto">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="w-9 h-9">
         <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
       </svg>

      </div>
      <div class="mt-3">
        <h3 class="text-lg font-semibold text-gray-800 ">{{ $t('sect_6') }}</h3>
        <p class="mt-1 text-gray-600 ">{{ $t('desc_sect_6') }}</p>
        <p class="mt-5 inline-flex items-center gap-x-1 text-black decoration-2 group-hover:underline font-medium bg-gray-200 p-2 rounded-md border-gray-400">
          {{ $t('btn_savoir_plus') }}
          <svg class="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6"/></svg>
        </p>
      </div>
    </div>
    <!-- End Icon Block -->

      <!-- Icon Block -->
      <div class="text-center">
      <div class="flex justify-center items-center w-16 h-16 bg-red-600 border border-gray-200 rounded-full mx-auto">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="w-9 h-9">
          <path stroke-linecap="round" stroke-linejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
        </svg>

      </div>
      <div class="mt-3">
        <h3 class="text-lg font-semibold text-gray-800 ">{{ $t('sect_7') }}</h3>
        <p class="mt-1 text-gray-600 ">{{ $t('desc_sect_7') }}</p>
        <p class="mt-5 inline-flex items-center gap-x-1 text-black decoration-2 group-hover:underline font-medium bg-gray-200 p-2 rounded-md border-gray-400">
          {{ $t('btn_savoir_plus') }}
          <svg class="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6"/></svg>
        </p>
      </div>
    </div>
    <!-- End Icon Block -->

      <!-- Icon Block -->
      <div class="text-center">
      <div class="flex justify-center items-center w-16 h-16 bg-red-600 border border-gray-200 rounded-full mx-auto">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="w-9 h-9">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 3v17.25m0 0c-1.472 0-2.882.265-4.185.75M12 20.25c1.472 0 2.882.265 4.185.75M18.75 4.97A48.416 48.416 0 0 0 12 4.5c-2.291 0-4.545.16-6.75.47m13.5 0c1.01.143 2.01.317 3 .52m-3-.52 2.62 10.726c.122.499-.106 1.028-.589 1.202a5.988 5.988 0 0 1-2.031.352 5.988 5.988 0 0 1-2.031-.352c-.483-.174-.711-.703-.59-1.202L18.75 4.971Zm-16.5.52c.99-.203 1.99-.377 3-.52m0 0 2.62 10.726c.122.499-.106 1.028-.589 1.202a5.989 5.989 0 0 1-2.031.352 5.989 5.989 0 0 1-2.031-.352c-.483-.174-.711-.703-.59-1.202L5.25 4.971Z" />
       </svg>
      </div>
      <div class="mt-3">
        <h3 class="text-lg font-semibold text-gray-800 ">{{ $t('sect_8') }}</h3>
        <p class="mt-1 text-gray-600 ">{{ $t('desc_sect_8') }}</p>
        <p class="mt-5 inline-flex items-center gap-x-1 text-black decoration-2 group-hover:underline font-medium bg-gray-200 p-2 rounded-md border-gray-400">
          {{ $t('btn_savoir_plus') }}
          <svg class="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6"/></svg>
        </p>
      </div>
    </div>
    <!-- End Icon Block -->

      <!-- Icon Block -->
      <div class="text-center">
      <div class="flex justify-center items-center w-16 h-16 bg-red-600 border border-gray-200 rounded-full mx-auto">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="w-9 h-9">
         <path stroke-linecap="round" stroke-linejoin="round" d="M22 10.5h-6m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM4 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 10.374 21c-2.331 0-4.512-.645-6.374-1.766Z" />
       </svg>

      </div>
      <div class="mt-3">
        <h3 class="text-lg font-semibold text-gray-800 ">{{ $t('sect_9') }}</h3>
        <p class="mt-1 text-gray-600 ">{{ $t('desc_sect_9') }}</p>
        <p class="mt-5 inline-flex items-center gap-x-1 text-black decoration-2 group-hover:underline font-medium bg-gray-200 p-2 rounded-md border-gray-400">
          {{ $t('btn_savoir_plus') }}
          <svg class="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6"/></svg>
        </p>
      </div>
    </div>
    <!-- End Icon Block -->
  </div>

</div>
<!-- End Hero -->



 <!-- Grid -->
 <div class="bg-slate-100">

  <!-- Team -->
<div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 mt-5 mx-auto">
  <!-- Title -->
  <div class="max-w-2xl mx-auto text-center mb-3">
    <h2 class="text-2xl font-bold md:text-4xl md:leading-tight ">{{ $t('faq_title') }}</h2>
    <p class="mt-1 text-gray-600 dark:text-gray-400">{{ $t('faq_quest') }}</p>
  </div>
  <!-- End Title -->

 
</div>
<!-- End Team -->
  <div class="max-w-[85rem] mx-auto mt-5 px-4 sm:px-6 lg:px-8 mb-8">
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-5  ">
      <div class="flex flex-col rounded-xl p-4 md:p-6 bg-white border border-red-600">
        <div class="flex items-center gap-x-4">
          <div class="grow">
            <h3 class="font-medium text-gray-800 mb-3">
              {{ $t('faq_quest') }}
            </h3>
            <p class="text-sm mt-1 text-gray-600">
              {{ $t('desc_envisager_immigrer_canada') }}            
            </p>
          </div>
        </div>

      </div>

      <div class="flex flex-col rounded-xl p-4 md:p-6 bg-white border border-red-600">
        <div class="flex items-center gap-x-4">
          <div class="grow">
            <h3 class="font-medium text-gray-800 mb-3">
              {{ $t('Quest_prevoyer_installer_montreal') }}           
               </h3>
            <p class="text-sm mt-1 text-gray-600">
              {{ $t('desc_prevoyer_installer_montreal') }}           
               </p>
          </div>
        </div>

      </div>

      <div class="flex flex-col rounded-xl p-4 md:p-6 bg-white border border-red-600">
        <div class="flex items-center gap-x-4">
          <div class="grow">
            <h3 class="font-medium text-gray-800 mb-3">
              {{ $t('Quest_visa_travail') }} 
            </h3>
            <p class="text-sm mt-1 text-gray-600">
              {{ $t('desc_visa_travail') }} 
              </p>
          </div>
        </div>

      </div>   
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-8">
      <div class="flex flex-col rounded-xl p-4 md:p-6 bg-white border border-red-600">
        <div class="flex items-center gap-x-4">
          <div class="grow">
            <h3 class="font-medium text-gray-800 mb-3">
              {{ $t('faq_quest') }} 
            </h3>
            <p class="text-sm mt-1 text-gray-600">
              {{ $t('desc_envisager_immigrer_canada') }} 
            </p>
          </div>
        </div>

      </div>

      <div class="flex flex-col rounded-xl p-4 md:p-6 bg-white border border-red-600">
        <div class="flex items-center gap-x-4">
          <div class="grow">
            <h3 class="font-medium text-gray-800 mb-3">
              {{ $t('Quest_parrainer_membre') }} 
            </h3>
            <p class="text-sm mt-1 text-gray-600">
              {{ $t('desc_parrainer_membre') }} 
              </p>
              </div>
        </div>

      </div>

      <div class="flex flex-col rounded-xl p-4 md:p-6 bg-white border border-red-600">
        <div class="flex items-center gap-x-4">
          <div class="grow">
            <h3 class="font-medium text-gray-800 mb-3">
              {{ $t('Quest_deja_arreter') }} 
            </h3>
            <p class="text-sm mt-1 text-gray-600">
              {{ $t('desc_deja_arreter') }} 
              </p>            
              </div>
        </div>

      </div>   
    </div>
  <!-- End Grid -->
 </div>
 </div>

<FooterViewVue/>
 







</template>

<script>
// @ is an alias to /src
import HeaderView from './templates/HeaderView.vue';
import VueWriter from 'vue-writer';
import FooterViewVue from './templates/FooterView.vue';

export default {
  name: 'HomeView',
  components: {
    HeaderView,
    VueWriter,
    FooterViewVue
  },
  data() {
      return {
         arr: [ this.$t('dynamic_slide1'), this.$t('dynamic_slide2')] 
        };
    },
}
</script>
