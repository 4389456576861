import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AgenceVoyageView from '../views/AgenceVoyageView.vue'
import PortailView from '../views/templates/PortailView.vue'

const routes = [
  {
    path: '/',
    name: 'portail',
    component: PortailView
  },
  {
    path: '/immigration',
    name: 'immigration',
    component: HomeView
  },
  {
    path: '/agence-voyage',
    name: 'agence-voyage',
    component: AgenceVoyageView
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
